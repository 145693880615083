import Button from "./Button";

const HomeCard = ({
  title,
  duration,
  src,
  status,
  handleShortsClick,
  onClickDelete,
  onClickRefresh,
  displayMessage,
}) => {
  return (
    <div
      className={`relative flex flex-col justify-between shadow-xl p-4 rounded-3xs border-solid border-2 ${
        status === "Success"
          ? "border-green"
          : status === "Failed"
          ? "border-red"
          : "border-orange"
      }`}
    >
      <img className="w-full" src={src} alt="thumbnail" />
      <div className="flex justify-between">
        <p className="text-xs">{title}</p>
        <p className="text-xs">{duration}</p>
      </div>
      <div className="flex justify-between mb-1">
        <p
          className={`w-3/4 text-xs my-0  self-center ${
            status === "Success"
              ? "text-green"
              : status === "Failed"
              ? "text-red"
              : "text-orange"
          }`}
        >
          {status}
        </p>
        {status === "Uploaded" || status === "Processing" ? (
          <button
            className="flex bg-white shadow-xl p-3 rounded-full place-self-center cursor-pointer border border-solid border-blue3 hover:bg-blue3"
            onClick={onClickRefresh}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.2em"
              viewBox="0 0 512 512"
            >
              <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8 62.5-62.5 163.8-62.5 226.3 0l17.1 17.2H336c-17.7 0-32 14.3-32 32s14.3 32 32 32h127.9c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2l-17.5-17.6c-87.5-87.5-229.3-87.5-316.8 0-24.4 24.4-42 53.1-52.8 83.8-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2-4 4-6.7 8.8-8.1 14-.3 1.2-.6 2.5-.8 3.8-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32v-51.1l17.6 17.5c87.5 87.4 229.3 87.4 316.7 0 24.4-24.4 42.1-53.1 52.9-83.7 5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8-62.5 62.5-163.8 62.5-226.3 0l-.1-.1-17.1-17.1H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2.1-4.8.3s-3.1.5-4.6 1z" />
            </svg>
          </button>
        ) : (
          <></>
        )}
      </div>

      {displayMessage && (status === "Uploaded" || status === "Processing") && (
        <p className="m-0 mb-1 text-xs text-blue3 opacity-95">
          Please refresh after some time!
        </p>
      )}
      <div className="flex justify-between ">
        <Button
          className={`w-4/5 font-kumbhsans px-4 py-4 rounded-3xs ${
            status !== "Success"
              ? "bg-cream cursor-not-allowed text-blue3"
              : "bg-blue3 cursor-pointer text-cream"
          }`}
          type="button"
          label="View Shorts"
          disabled={status !== "Success" ? true : false}
          onClick={handleShortsClick}
        />
        <button
          className="flex bg-white shadow-xl p-3 rounded-full place-self-center cursor-pointer border border-solid border-red hover:bg-red"
          onClick={onClickDelete}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1.2em"
            viewBox="0 0 448 512"
          >
            <path d="M170.5 51.6l-19 28.4h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6h-93.7c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6l36.7 55H424c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8v304c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128h-8c-13.3 0-24-10.7-24-24s10.7-24 24-24h69.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128v304c0 17.7 14.3 32 32 32h224c17.7 0 32-14.3 32-32V128H80zm80 64v208c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0v208c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0v208c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default HomeCard;
