import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../services/auth";
//components
import Canvas from "../components/Canvas";
import InputField from "../components/InputField";
import Button from "../components/Button";
import Navbar from "../components/Navbar";

const Url = () => {
  const [link, setLink] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await AuthService.uploadVideo(link);
      //navigate to home page
      navigate("/home");
    } catch (error) {
      const errorMessage = error.response.detail;
      console.log("error", error.response);
      setErrorMessage(true);
    }
  };

  return (
    <div className="h-screen overflow-hidden">
      <Navbar />
      <div className="mt-4 grid grid-cols-2 gap-4 h-full">
        <Canvas />
        <div className="mx-10 flex flex-col justify-center">
          <div className="text-l font-medium">Import your videos here!</div>
          <InputField
            placeholder="Enter link here"
            type="link"
            value={link}
            onChange={handleLinkChange}
            className="font-kumbhsans mt-10 w-6/12 p-4 rounded-3xs bg-cream"
          />
          {errorMessage && (
            <p className="text-red">
              Invalid URL! {"\n"}
              Sample URL format - https://www.youtube.com/watch?v=PZtwxD5Myk0
            </p>
          )}
          <Button
            className="font-kumbhsans mt-4 w-6/12 p-4 rounded-3xs bg-blue3 cursor-pointer text-cream"
            label="Create Shorts!"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default Url;
