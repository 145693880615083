import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthService } from "../services/auth";

//components
import Canvas from "../components/Canvas";
import Button from "../components/Button";
import InputField from "../components/InputField";

const Login = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await AuthService.signup({ name, email, password });
      // Navigate to the login page;
      navigate("/login");
    } catch (error) {
      const errorMessage = error.response.data.detail;
      setErrorMessage(errorMessage);
    }
  };

  return (
    <div className="grid grid-cols-2 gap-4 h-full">
      <div className="mx-10 flex flex-col justify-center">
        <form onSubmit={handleSubmit}>
          <div className="text-l font-medium">Get Started Now!</div>
          <div className="mt-4">Start your journey with us</div>
          <InputField
            placeholder="Name"
            type="name"
            value={name}
            onChange={handleNameChange}
            className="font-kumbhsans mt-14 w-6/12 p-4 rounded-3xs bg-cream"
          />
          <InputField
            placeholder="Email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            className="font-kumbhsans mt-4 w-6/12 p-4 rounded-3xs bg-cream"
          />
          <InputField
            placeholder="Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            className="font-kumbhsans mt-4 w-6/12 p-4 rounded-3xs bg-cream"
          />
          <p className="text-red">{errorMessage}</p>
          <Button
            className="font-kumbhsans mt-10 w-6/12 p-4 rounded-3xs bg-blue3 cursor-pointer text-cream"
            label="Let's Begin!"
            type="submit"
          />
          <div className=" mt-4 cursor-pointer">
            <span>Have an account? </span>
            <Link to="/login" className="text-blue3">
              Sign in here
            </Link>
          </div>
        </form>
      </div>
      <Canvas />
    </div>
  );
};

export default Login;
