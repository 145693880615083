import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import AuthService from "../services/auth";

//components
import Canvas from "../components/Canvas";
import Button from "../components/Button";
import InputField from "../components/InputField";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleEmailChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await AuthService.login({ username, password });
      setLoggedIn(true);
    } catch (error) {
      const errorMessage = error.response.data.detail;
      setErrorMessage(errorMessage);
    }
  };

  if (loggedIn || AuthService.isAuthenticated()) {
    return <Navigate to="/home" />;
  }

  return (
    <div className="grid grid-cols-2 gap-4 h-full">
      <Canvas />
      <div className="mx-10 flex flex-col justify-center">
        <form onSubmit={handleSubmit}>
          <div className="text-l font-medium">Welcome!</div>
          <div className="mt-4">
            Enter your credentials to access your account
          </div>
          <InputField
            placeholder="Email"
            type="email"
            value={username}
            onChange={handleEmailChange}
            className="font-kumbhsans mt-14 w-6/12 p-4 rounded-3xs bg-cream"
          />
          <InputField
            placeholder="Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            className="font-kumbhsans mt-4 w-6/12 p-4 rounded-3xs bg-cream"
          />
          {/* <div className="text-steelblue">Forgot password?</div> */}
          <p className="text-red">{errorMessage}</p>
          <Button
            className=" font-kumbhsans mt-10 w-6/12 p-4 rounded-3xs bg-blue3 cursor-pointer text-cream"
            label="Login"
            type="submit"
          />
          <div className="mt-4">
            <span>New here? </span>
            <Link to="/signup" className="cursor-pointer text-blue3">
              Create an account
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
