import Button from "./Button";

const ShortsPlayer = ({
  videoSrc,
  backToEdit,
  downloadSrc,
  title,
  downloadName,
}) => {
  return (
    <div className="flex flex-col mr-4">
      <p className="text-l">{title}</p>
      <video className="mt-4 h-3/6 " src={videoSrc} controls autoPlay />
      <div className="flex justify-between">
        <Button
          type="button"
          className="w-1/4 font-kumbhsans mt-10 py-4 px-10 rounded-3xs bg-blue3 cursor-pointer text-cream"
          label="Back to Edit"
          onClick={backToEdit}
        />
        <a
          className="w-1/4"
          href={downloadSrc}
          download={downloadName + ".mp4"}
        >
          <Button
            type="button"
            className="w-full font-kumbhsans mt-10 py-4 px-10 rounded-3xs bg-blue3 cursor-pointer text-cream"
            label="Download"
          />
        </a>
      </div>
    </div>
  );
};

export default ShortsPlayer;
