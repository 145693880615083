import React, { useState, useEffect } from "react";
import error from "../assets/404.png";

const Error = () => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="h-screen overflow-hidden text-center">
      <img className="object-contain h-full w-full" src={error} alt="error" />
    </div>
  );
};

export default Error;
