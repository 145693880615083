const Canvas = () => {
  return (
    <div className="flex items-center justify-center bg-blue3 w-full">
      <div className="flex-col items-center text-[48px] text-floralwhite-100">
        <p className="text-cream m-0 text-[48px]">Create short videos from</p>
        <p className="text-cream m-0 text-[48px]">long ones instantly!</p>
      </div>
    </div>
  );
};

export default Canvas;
