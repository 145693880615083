const ShortsCard = ({
  src,
  alt,
  title,
  totalDuration,
  duration,
  startTime,
  endTime,
  onClick,
  selectedItem,
  shortId,
  status,
}) => {
  return (
    <button
      className={`shadow-xl cursor-pointer mx-10 rounded-3xs font-kumbhsans h-28 ${
        duration >= 60 ? "border-solid border-2 border-red" : ""
      } ${
        selectedItem === shortId ? "bg-cream text-blue3" : "bg-white text-black"
      }`}
      onClick={onClick}
    >
      <div className="flex justify-between item-center mx-10 rounded-3xs h-3/4">
        <div className="w-1/6 h-full">
          <img className="h-full w-full" src={src} alt={alt} />
        </div>
        <div className="flex flex-col h-full justify-between w-3/5">
          <p className="h-1/2 text-start text-xs">{title}</p>
          <p className="m-0 text-start text-xs">
            {startTime} - {endTime}
          </p>
        </div>
        <div className="flex flex-col h-full justify-between w-fit">
          <p className="text-xs">{totalDuration}</p>
          <p
            className={`m-0 text-xs ${
              status === "Review"
                ? "text-blue3"
                : status === "Generating" || status === "Processing"
                ? "text-orange"
                : "text-green"
            }`}
          >
            {status}
          </p>
        </div>
      </div>
    </button>
  );
};

export default ShortsCard;
