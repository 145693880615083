import React from "react";
import { Route, Routes } from "react-router-dom";

//pages
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import Url from "../pages/Url";
import Home from "../pages/Home";
import Shorts from "../pages/Shorts";
import Error from "../pages/Error";

function Routing() {
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/signup" element={<Signup />} />
      <Route exact path="/addvideo" element={<Url />} />
      <Route exact path="/home" element={<Home />} />
      <Route exact path="/shorts/:videoId" element={<Shorts />} />
      <Route exact path="*" element={<Error />} />
    </Routes>
  );
}

export default Routing;
