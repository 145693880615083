import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../services/auth";

//components
import Navbar from "../components/Navbar";
import Button from "../components/Button";
import HomeCard from "../components/HomeCard";

const Home = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [videoDetails, setVideoDetails] = useState([]);
  const [getVideoData, setGetVideoData] = useState([]);
  const [message, setMessage] = useState(false);

  const navigate = useNavigate();
  const placeholder = "https://placehold.co/340x191";

  useEffect(() => {
    // Function to fetch user details from the API
    AuthService.fetchUserDetails()
      .then((userData) => {
        setUserDetails(userData);
      })
      .catch((error) => {
        console.log("Error fetching user data", error);
      });
    // Function to fetch video details from the API
    AuthService.fetchVideosDetails()
      .then((videosData) => {
        setVideoDetails(videosData);
      })
      .catch((error) => {
        console.log("Error fetching video data", error);
      });
  }, []);

  const handleShortsClick = async (video_id) => {
    // Function to fetch shorts from API and navigate to shorts page
    AuthService.fetchShorts(video_id)
      .then((shortsData) => {
        navigate(`/shorts/${video_id}`);
      })
      .catch((error) => {
        console.log("Error fetching shorts data", error);
      });
  };

  const handleClick = (e) => {
    e.preventDefault();
    // Navigate to the import page
    navigate("/addvideo");
  };

  const onClickRefresh = async (video_id) => {
    setMessage(true);
    // Function to fetch video details from the API
    AuthService.fetchVideosDetails()
      .then((videosData) => {
        setVideoDetails(videosData);
        videosData.map((item) =>
          item.short_video_id === video_id ? { ...videoDetails } : item
        );
      })
      .catch((error) => {
        console.log("Error fetching video data", error);
      });
  };

  const onClickDelete = async (video_id) => {
    AuthService.deleteVideo(video_id)
      .then((deleteData) => {
        // refresh the page
        navigate(0);
      })
      .catch((error) => {
        console.log("Error deleting video", error);
      });
  };

  return (
    <div className="">
      <Navbar />
      <div className="mt-4 p-4 flex flex-row items-center justify-between bg-cream">
        <div className="text-l font-medium">Welcome {userDetails}!</div>
        <Button
          className="font-kumbhsans px-10 py-4 rounded-3xs bg-blue3 cursor-pointer text-cream"
          type="button"
          label="Upload"
          onClick={handleClick}
        />
      </div>
      {videoDetails.length === 0 ? (
        <div>
          <p className="p-20 text-s font-medium  text-center">
            You have no videos as of now. Please upload one!
          </p>
        </div>
      ) : (
        <div className="mx-4 my-8 grid grid-cols-5 gap-4 ">
          {videoDetails.map((videoDetail) => (
            <HomeCard
              key={videoDetail.video_id}
              src={
                videoDetail.thumbnail === null
                  ? placeholder
                  : videoDetail.thumbnail
              }
              title={videoDetail.title}
              duration={videoDetail.total_duration}
              status={videoDetail.status}
              handleShortsClick={() => handleShortsClick(videoDetail.video_id)}
              onClickDelete={() => onClickDelete(videoDetail.video_id)}
              onClickRefresh={() => onClickRefresh(videoDetail.video_id)}
              displayMessage={message}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Home;
