import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./CustomVideoPlayer.css";
import Button from "./Button";
import InputField from "./InputField";

export const VideoJS = ({
  videoUrl,
  startTime,
  endTime,
  onReady,
  title,
  options,
  handleEdit,
  onClickAddLogo,
  onClickAddIntro,
  onClickAddOutro,
  label,
  onClickView,
  status,
  onClickRefresh,
  sendTitleData,
  sendStartTime,
  sendEndTime,
  sendLogo,
  reset,
  duration,
}) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const currentDuration = useRef(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [editedTitle, setEditedTitle] = useState(null);
  const [subclipStart, setSubclipStart] = useState(0);
  const [subclipEnd, setSubclipEnd] = useState(0);

  useEffect(() => {
    if (!reset) {
      setSubclipStart(startTime);
      setSubclipEnd(endTime);
    }

    if (!playerRef.current) {
      const player = (playerRef.current = videojs(
        videoRef.current,
        {
          controls: true,
          // disablePictureInPicture: true,
        },
        () => {
          videojs.log("player is ready");
          // console.log("God", player.currentTime);
          // onReady && onReady(player);
          onReady(player, currentDuration);
        }
      ));
      player.controlBar.progressControl.disable();
      // player.controlBar.progressControl.hide();
      // player.controlBar.progressControl.el().style.visibility = "hidden";
      player.controlBar.el().style.display = "none";

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
    }
    const instance = videoRef.current;
    if (instance) {
      instance.addEventListener("timeupdate", handleTimeUpdate);
      return () => {
        instance.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [options, videoRef, reset]);

  const onEditTitle = (e) => {
    const passTitle = e.target.value;
    setEditedTitle(passTitle);
    sendTitleData(passTitle);
  };

  const handleSetSubclipStart = () => {
    const passStartTime = currentTime;
    setSubclipStart(passStartTime);
    sendStartTime(passStartTime);
  };

  const handleSetSubclipEnd = () => {
    const passEndTime = currentTime;
    setSubclipEnd(passEndTime);
    sendEndTime(passEndTime);
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleSliderChange = (event) => {
    if (videoRef.current) {
      const newTime = videoRef.current.duration * (event.target.value / 100);
      videoRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between mr-4 items-center">
        <p className="text-m">{title}</p>
        <div className="flex items-center p-2 rounded-3xs h-2/5 bg-white shadow-xl">
          <p
            className={`px-2 ${
              status === "Review"
                ? "text-blue3"
                : status === "Generating" || status === "Processing"
                ? "text-orange"
                : "text-green"
            }`}
          >
            {status}
          </p>
          {status === "Review" || status === "Generated" ? (
            <div className="pr-2"></div>
          ) : (
            <button
              className="flex shadow-xl mr-2 p-2 rounded-full place-self-center cursor-pointer bg-cream border border-solid border-blue3 hover:bg-blue3"
              onClick={onClickRefresh}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8 62.5-62.5 163.8-62.5 226.3 0l17.1 17.2H336c-17.7 0-32 14.3-32 32s14.3 32 32 32h127.9c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2l-17.5-17.6c-87.5-87.5-229.3-87.5-316.8 0-24.4 24.4-42 53.1-52.8 83.8-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2-4 4-6.7 8.8-8.1 14-.3 1.2-.6 2.5-.8 3.8-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32v-51.1l17.6 17.5c87.5 87.4 229.3 87.4 316.7 0 24.4-24.4 42.1-53.1 52.9-83.7 5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8-62.5 62.5-163.8 62.5-226.3 0l-.1-.1-17.1-17.1H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2.1-4.8.3s-3.1.5-4.6 1z" />
              </svg>
            </button>
          )}
        </div>
      </div>

      <video
        ref={videoRef}
        className="video-js vjs-default-skin"
        // controls={false} // Disable native controls
        preload="auto"
        src={videoUrl}
        type="video/mp4"
      />
      <div className="mt-4 mr-4">
        <input
          type="range"
          min="0"
          max="100"
          step="1"
          className="w-full"
          onChange={handleSliderChange}
          value={
            (currentDuration.current / (videoRef.current?.duration || 1)) * 100
          }
        />
        <div className="flex justify-between items-center ">
          <p className="m-0">{formatTime(0)}</p>
          <p className="m-0 p-2 shadow-md rounded-3xs">
            {formatTime(currentDuration.current)}
          </p>
          <p className="m-0">{formatTime(videoRef.current?.duration)}</p>
        </div>
        <div className="flex justify-between">
          <Button
            label="Set Start"
            type="button"
            value={subclipStart}
            onClick={handleSetSubclipStart}
            className="font-kumbhsans px-10 py-4 rounded-3xs cursor-pointer bg-blue3 text-white"
          />
          <Button
            className="font-kumbhsans px-10 py-4 rounded-3xs cursor-pointer bg-blue3 text-white"
            label="Set End"
            type="button"
            value={subclipEnd}
            onClick={handleSetSubclipEnd}
          />
        </div>
      </div>
      <div className="flex items-center justify-between mr-4">
        <p>Subclip Start - {formatTime(subclipStart)}</p>
        <p>Subclip Duration - {formatTime(subclipEnd - subclipStart)}</p>
        <p>Subclip End: {formatTime(subclipEnd)}</p>
      </div>
      {subclipEnd - subclipStart > 120 ? (
        <p className="m-0 p-1 text-center text-red border border-solid border-red rounded-s">
          Please set the subclip duration to be below 2 minutes!
        </p>
      ) : (
        <></>
      )}

      <form onSubmit={handleEdit}>
        <div className="">
          <p className="text-m mb-2">Edit Title</p>
          <InputField
            type="text"
            name="title"
            placeholder={title}
            value={editedTitle}
            className="font-kumbhsans p-4 rounded-3xs bg-cream mr-4 w-11/12 text-blue3"
            onChange={onEditTitle}
          />
        </div>
        <div className="flex justify-between">
          <div className="w-1/3 ">
            <p className="text-m mb-2">Add Logo</p>
            <InputField
              type="file"
              name="logo"
              accept="image/*"
              className="w-4/5 p-4 rounded-3xs font-kumbhsans bg-white text-blue3 cursor-pointer border-solid border-2 border-sky-500"
              onChange={onClickAddLogo}
            />
          </div>
          <div className="w-1/3">
            <p className="text-m mb-2">Add Intro</p>
            <InputField
              type="file"
              accept="video/*"
              className="w-4/5 p-4 rounded-3xs font-kumbhsans bg-white text-blue3 cursor-pointer border-solid border-2 border-sky-500"
              onChange={onClickAddIntro}
            />
          </div>
          <div className="w-1/3">
            <p className="text-m mb-2">Add Outro</p>
            <InputField
              type="file"
              accept="video/*"
              className="w-4/5 p-4 rounded-3xs font-kumbhsans bg-white text-blue3 cursor-pointer border-solid border-2 border-sky-500"
              onChange={onClickAddOutro}
            />
          </div>
        </div>
        <div className="my-10 mr-4 flex justify-between">
          <Button
            type="submit"
            className={`w-2/5 font-kumbhsans py-4 px-10 rounded-3xs ${
              status === "Processing" ||
              status === "Generating" ||
              subclipEnd - subclipStart > 120
                ? "bg-cream text-blue 3 cursor-not-allowed"
                : "bg-blue3 text-cream cursor-pointer"
            }`}
            label={label}
            disabled={
              status === "Processing" ||
              status === "Generating" ||
              subclipEnd - subclipStart > 120
                ? true
                : false
            }
          />
          {status === "Generated" ? (
            <Button
              type="button"
              className="ml-16 w-2/5 font-kumbhsans py-4 px-10 rounded-3xs bg-blue3 cursor-pointer text-cream "
              label="View Short"
              onClick={onClickView}
            />
          ) : (
            <div></div>
          )}
        </div>
      </form>
    </div>
  );
};

function formatTime(timeInSeconds) {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
}

export default VideoJS;
