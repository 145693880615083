import jwt_decode from "jwt-decode";
import axios from "axios";
import { baseUrl } from "../utils/constants";

const API_BASE_URL = baseUrl;
const ACCESS_TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";

export const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 5000,
});

export const AuthService = {
  // Signup user and push to login
  signup: async ({ name, email, password }) => {
    let data = JSON.stringify({
      name: name,
      email: email,
      password: password,
    });

    let config = {
      method: "post",
      url: "/api/users/",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: data,
    };
    try {
      const signupResponse = await api.request(config);
      return signupResponse;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },

  // Login user and get JWT access and refresh tokens
  login: async ({ username, password }) => {
    try {
      const data = new URLSearchParams();
      data.append("username", username);
      data.append("password", password);
      let config = {
        method: "post",
        url: "/api/login",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        data: data,
      };

      const loginResponse = await api.request(config);
      const { access_token, refresh_token } = loginResponse.data;
      localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
      localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },

  // Logout user and remove JWT tokens
  logout: () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },

  // Check if the user is authenticated (access token exists and is not expired)
  isAuthenticated: () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    return accessToken && !AuthService.isTokenExpired(accessToken);
  },

  // Check if the access token is expired
  isTokenExpired: (token) => {
    try {
      const decoded = jwt_decode(token);
      return decoded.exp < Date.now() / 1000;
    } catch (error) {
      return true;
    }
  },

  // Get the access token
  getAccessToken: () => {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  },

  // Get the refresh token
  getRefreshToken: () => {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  // Refresh the access token using the refresh token
  refreshToken: async () => {
    try {
      const refreshToken = AuthService.getRefreshToken();
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/refresh-token",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${refreshToken}`,
        },
      };
      if (!refreshToken) {
        AuthService.logout();
        return null;
      }
      const response = await api.request(config);
      const { access_token } = response.data;
      localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
      return access_token;
    } catch (error) {
      AuthService.logout();
      console.error("Error refreshing access token", error);
      throw error;
    }
  },

  // Upload youtube video
  uploadVideo: async (link) => {
    try {
      const accessToken = AuthService.getAccessToken();
      let data = JSON.stringify({
        youtube_url: link,
      });
      let config = {
        method: "post",
        url: "/api/videos/youtube",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      };
      const response = await api.request(config);
      return response;
    } catch (error) {
      console.error("Error uploading video", error);
      throw error;
    }
  },

  fetchVideoDetailByID: async (video_id) => {
    const accessToken = AuthService.getAccessToken();

    let configVideo = {
      method: "get",
      url: `/api/videos/${video_id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const responseVideo = await api.request(configVideo);
      const videoData = await responseVideo.data;
      return videoData;
    } catch (error) {
      console.error("Error fetching video data", error);
    }
  },

  //fetch video details from API
  fetchVideosDetails: async () => {
    const accessToken = AuthService.getAccessToken();

    let configVideo = {
      method: "get",
      url: "/api/videos/",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const responseVideo = await api.request(configVideo);
      const videosData = await responseVideo.data;
      return videosData;
    } catch (error) {
      console.error("Error fetching video data", error);
    }
  },

  //fetch user details from API
  fetchUserDetails: async () => {
    const accessToken = AuthService.getAccessToken();

    let configUser = {
      method: "get",
      url: "/api/users/me",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const responseUser = await api.request(configUser);
      const userData = await responseUser.data.name;
      return userData;
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  },

  //Api call to fetch shorts
  fetchShorts: async (video_id) => {
    const accessToken = AuthService.getAccessToken();
    const videoId = video_id;

    let configShorts = {
      method: "get",
      url: `/api/videos/${videoId}/shorts`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const responseShorts = await api.request(configShorts);
      const shortsData = await responseShorts.data;
      return shortsData;
    } catch (error) {
      console.error("Error fetching shorts data", error);
    }
  },

  //Api call to delete video
  deleteVideo: async (video_id) => {
    const accessToken = AuthService.getAccessToken();
    const videoId = video_id;

    let configDelete = {
      method: "delete",
      url: `/api/videos/${videoId}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      await api.request(configDelete);
    } catch (error) {
      console.error("Error deleting video", error);
    }
  },

  //Api call to generate shorts video (final render)
  generateShorts: async ({ short_video_id, logo, intro, outro }) => {
    const accessToken = AuthService.getAccessToken();
    const shortsId = short_video_id;
    let data = null;

    if (logo !== null || intro !== null || outro !== null) {
      data = new FormData();

      if (logo !== null) {
        data.append("logo", logo);
      }

      if (intro !== null) {
        data.append("intro_video", intro);
      }

      if (outro !== null) {
        data.append("outro_video", outro);
      }
    }

    let configGenerateShorts = {
      method: "post",
      url: `/api/shorts/${shortsId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    };

    try {
      const responseGenerateShorts = await api.request(configGenerateShorts);
      const generateShortsData = await responseGenerateShorts.data;
      return generateShortsData;
    } catch (error) {
      console.error("Error generating shorts", error);
    }
  },

  //Api call to get final video with logo and outro
  getShorts: async (short_video_id) => {
    const accessToken = AuthService.getAccessToken();
    const shortId = short_video_id;

    let configGetShorts = {
      method: "get",
      url: `/api/shorts/${shortId}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const responseGetShorts = await api.request(configGetShorts);
      const getShortsData = await responseGetShorts.data;
      return getShortsData;
    } catch (error) {
      console.error("Error generating shorts", error);
    }
  },

  //Api call to patch title, start and end times
  updateShorts: async ({
    short_video_id,
    newTitle,
    newStartTime,
    newEndTime,
  }) => {
    const accessToken = AuthService.getAccessToken();
    const shortId = short_video_id;
    let data = {
      title: newTitle,
      start_time_ms: Math.floor(newStartTime * 1000),
      end_time_ms: Math.floor(newEndTime * 1000),
    };

    let configUpdateShorts = {
      method: "patch",
      url: `/api/shorts/${shortId}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    };
    try {
      const responseUpdateShorts = await api.request(configUpdateShorts);
      const getUpdatedShortsData = await responseUpdateShorts.data;
      return getUpdatedShortsData;
    } catch (error) {
      console.error("Error updating shorts", error);
    }
  },
};

export default AuthService;
