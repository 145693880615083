import { Link, Navigate } from "react-router-dom";
import AuthService from "../services/auth";
import { useEffect, useState } from "react";

import Button from "../components/Button";
import image from "../assets/navbar.png";

const Navbar = () => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [showHome, setShowHome] = useState("");

  useEffect(() => {
    //conditional render for home
    if (window.location.pathname !== "/home") {
      setShowHome(true);
    }
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    // Clear the token in local storage
    AuthService.logout();
    setLoggedIn(false);
  };

  if (
    !AuthService.isAuthenticated() &&
    !AuthService.isTokenExpired(AuthService.getAccessToken())
  ) {
    AuthService.refreshToken();
  }

  if (!loggedIn || !AuthService.isAuthenticated()) {
    AuthService.logout();
    return <Navigate to="/" />;
  }

  return (
    <nav className="m-4 flex flex-row items-center justify-between">
      <img className="h-14 w-14" src={image} alt="Logo" />
      {showHome === true ? (
        <Link to="/home" className="text-black no-underline hover:text-blue3">
          Back to Homepage
        </Link>
      ) : null}
      <div className="">
        <Button
          className="font-kumbhsans px-10 py-4 rounded-3xs cursor-pointer bg-white text-blue3 border-solid border-2 border-sky-500"
          type="button"
          label="Logout"
          onClick={handleLogout}
        />
      </div>
    </nav>
  );
};

export default Navbar;
