import { BrowserRouter } from "react-router-dom";
import Layout from "./layout/Layout";
import Routing from "./routes/routes";
import { useEffect } from "react";
import AuthService from "./services/auth";

function App() {
  useEffect(() => {
    //Call refreshToken api on mount
    AuthService.refreshToken();

    // Set interval to call API every 30mins
    const refreshTokenInterval = setInterval(AuthService.refreshToken, 1800000);

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(refreshTokenInterval);
    };
  }, []);
  return (
    <Layout>
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </Layout>
  );
}

export default App;
