import React, { useState, useEffect, useRef } from "react";
import { AuthService } from "../services/auth";
import { useParams } from "react-router-dom";

//components
import Navbar from "../components/Navbar";
import ShortsCard from "../components/ShortsCard";
import ShortsPlayer from "../components/ShortsPlayer";
import CustomVideoPlayerWithSlider from "../components/CustomVideoPlayerWithSlider";
import TimerOverlay from "../components/TimerOverlay";

const Shorts = () => {
  const [showPlayer, setShowPlayer] = useState(false);
  const [showFinalPlayer, setShowFinalPlayer] = useState(false);
  let [selectedShort, setSelectedShort] = useState(null);
  const [shortsData, setShortsData] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoUrlWithTime, setVideoUrlWithTime] = useState(null);
  const [logo, setLogo] = useState(null);
  const [intro, setIntro] = useState(null);
  const [outro, setOutro] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newStartTime, setNewStartTime] = useState("");
  const [newEndTime, setNewEndTime] = useState("");
  const [resetTime, setResetTime] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const playerRef = useRef(null);
  const shortSelected = useRef(null);

  const { videoId } = useParams();

  useEffect(() => {
    AuthService.fetchVideoDetailByID(videoId)
      .then((videoData) => {
        setVideoUrl(videoData.video_path);
      })
      .catch((error) => {
        console.log("Error fetching video data", error);
      });
  }, [videoId]);

  useEffect(() => {
    //Api call to fetch shorts details
    AuthService.fetchShorts(videoId)
      .then((shortsData) => {
        setShortsData(shortsData);
        shortsData.map((item) =>
          item.short_video_id === videoId ? { ...shortsData } : item
        );
      })
      .catch((error) => {
        console.log("Error fetching shorts data", error);
      });
    //function to ser video url
    if (selectedShort !== null) {
      const my_video = videoUrl;
      setVideoUrlWithTime(
        `${my_video}#t=${selectedShort.start_time_ms / 1000}` //?v=1298723646`
      );
    }
  }, [selectedShort, videoUrl]);

  //function to show video player on click of shorts video
  const onClickVideo = (shorts) => {
    //Show video player
    setShowPlayer(true);
    setShowFinalPlayer(false);
    setShowOverlay(false);

    //get the data and store to state
    setSelectedShort(shorts);
    shortSelected.current = shorts;
    //
    setSelectedItem(shorts.short_video_id);
    //reset state values
    setResetTime(true);
    // Reset the resetChild state after a short delay
    setTimeout(() => {
      setResetTime(false);
    }, 500);
  };

  const handlePlayerReady = (player, currentDuration) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("play", () => {
      const endTime = shortSelected.current.end_time_ms / 1000;
      const startTime = shortSelected.current.start_time_ms / 1000;
      // console.log(
      //   "Play: " +
      //     playerRef.current.currentTime() +
      //     "-->" +
      //     startTime +
      //     "---" +
      //     endTime
      // );

      if (playerRef.current.currentTime() >= endTime) {
        playerRef.current.currentTime(startTime);
        playerRef.current.bigPlayButton.enable();
      }
    });

    player.on("timeupdate", () => {
      const endTime = shortSelected.current.end_time_ms / 1000;
      const startTime = shortSelected.current.start_time_ms / 1000;
      // console.log(
      //   "timeupdate: " +
      //     playerRef.current.currentTime() +
      //     "-->" +
      //     startTime +
      //     "---" +
      //     endTime
      // );

      if (playerRef.current.currentTime() >= endTime) {
        // playerRef.current.currentTime(startTime);
        playerRef.current.pause();
        // playerRef.current.bigPlayButton.enable();
      }
      currentDuration.current = playerRef.current.currentTime();
    });
  };

  //function to add logo
  const onClickAddLogo = (e) => {
    setLogo(e.target.files[0]);
  };

  //function to add intro
  const onClickAddIntro = (e) => {
    setIntro(e.target.files[0]);
  };

  //function to add outro
  const onClickAddOutro = (e) => {
    setOutro(e.target.files[0]);
  };

  // function to submit data to generate shorts Api
  const handleEdit = async (e) => {
    e.preventDefault();
    let short_video_id = shortSelected.current.short_video_id;
    try {
      if (newTitle !== null || newStartTime !== null || newEndTime !== null) {
        await AuthService.updateShorts({
          short_video_id,
          newTitle,
          newStartTime,
          newEndTime,
        });
      }
      await AuthService.generateShorts({
        short_video_id,
        logo,
        intro,
        outro,
      });
      AuthService.getShorts(short_video_id).then((getShortsData) => {
        setSelectedShort(getShortsData);
        shortsData.map((item) =>
          item.short_video_id === short_video_id ? { ...getShortsData } : item
        );
      });
    } catch (error) {
      console.log("Error fetching final shorts data", error);
    }
  };

  const handleBackToEdit = (e) => {
    e.preventDefault();
    //Show inital player
    setShowFinalPlayer(false);
  };

  const onClickViewShort = () => {
    //Show final player
    setShowFinalPlayer(true);
  };

  const onClickRefresh = (e) => {
    e.preventDefault();
    //show overlay
    setShowOverlay(true);
    //function to set 15 seconds timer for overlay
    setTimeout(() => {
      //hide overlay
      setShowOverlay(false);
    }, 15000); // 15 seconds in milliseconds
    let short_video_id = shortSelected.current.short_video_id;
    // Sync call to check if video is generated
    AuthService.getShorts(short_video_id)
      .then((getShortsData) => {
        setSelectedShort(getShortsData);
        shortsData.map((item) =>
          item.short_video_id === short_video_id ? { ...getShortsData } : item
        );
        //function to disable overlay if shorts is generated
        if (getShortsData.status === "Generated") {
          setShowOverlay(false);
        }
      })
      .catch((error) => {
        console.log("Error fetching final shorts data", error);
      });
  };

  // Functions required for patch shorts api
  const updateTitleData = (passTitle) => {
    setNewTitle(passTitle);
  };
  const updateStartTime = (passStartTime) => {
    setNewStartTime(passStartTime);
  };
  const updateEndTime = (passEndTime) => {
    setNewEndTime(passEndTime);
  };

  const thumbnailPlaceholder = "https://placehold.co/600x400";

  return (
    <div className="">
      <Navbar />
      <div className="relative mt-4 grid grid-cols-2 gap-4 h-screen">
        <div className="scrollable-content grid grid-flow-row gap-4 bg-blue3 overflow-y-scroll pb-10">
          <p className="ml-10 mb-0 text-l text-cream">Generated shorts</p>
          {shortsData.map((shorts) => (
            <ShortsCard
              key={shorts.short_video_id}
              src={
                shorts.thumbnail_url === null
                  ? thumbnailPlaceholder
                  : shorts.thumbnail_url
              }
              title={shorts.title}
              duration={shorts.duration}
              totalDuration={shorts.total_duration}
              startTime={shorts.start_time}
              endTime={shorts.end_time}
              onClick={() => onClickVideo(shorts)}
              selectedItem={selectedItem}
              shortId={shorts.short_video_id}
              status={shorts.status}
            />
          ))}
        </div>
        {showPlayer ? (
          showFinalPlayer ? (
            <ShortsPlayer
              title={selectedShort.title}
              videoSrc={selectedShort.final_video_path}
              downloadSrc={selectedShort.final_video_path}
              backToEdit={handleBackToEdit}
            />
          ) : (
            <CustomVideoPlayerWithSlider
              videoUrl={videoUrlWithTime}
              startTime={selectedShort.start_time_ms / 1000}
              endTime={selectedShort.end_time_ms / 1000}
              onReady={handlePlayerReady}
              title={selectedShort.title}
              sendTitleData={updateTitleData}
              sendStartTime={updateStartTime}
              sendEndTime={updateEndTime}
              onClickAddLogo={onClickAddLogo}
              onClickAddIntro={onClickAddIntro}
              onClickAddOutro={onClickAddOutro}
              handleEdit={handleEdit}
              status={selectedShort.status}
              label={
                selectedShort.status === "Generated"
                  ? "Regenerate Short"
                  : "Generate Short"
              }
              onClickView={onClickViewShort}
              onClickRefresh={onClickRefresh}
              reset={resetTime}
              duration={selectedShort.duration}
            />
          )
        ) : (
          <p className="text-l self-center justify-self-center">
            Please select a short to view!
          </p>
        )}
        {showOverlay && <TimerOverlay />}
      </div>
    </div>
  );
};

export default Shorts;
